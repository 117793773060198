import React from 'react';
import InfoHotspot from './InfoHotspot';
import LinkHotspots from './LinkHotspot';
import { HotspotsProps } from '../types/hotspots';
import './Hotspots.css';
import ImgHotspot from './ImgHotspot';
import LabelHotspot from './LabelHotspot';
import MediaHotspot from './MediaHotspot';
import ModalHotspot from './ModalHotspot';
import { Nadir } from '../components/nadir';
import {
  loadHotspots,
  gyroscope,
  stopAutoRotate,
  toggleLeftAndRightHotspots,
} from '../libs/marzipano';
import { decodeHash } from '../libs/utils';
import PAHotspot from './PAHotspot';
import { ReactComponent as PointerSVG } from '../icon/poin-ask.svg';
import { MarzipanoScene } from '../types/marzipano';
import ArticleHotspot from './ArticleHotspot';

export default class Hotspots extends React.Component<HotspotsProps> {
  componentDidMount = () => {
    let values = undefined;
    let code;
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      code = urlParams.get('loc');
    }
    if (code) {
      values = decodeHash(code);
    }
    loadHotspots(this.props.appdata, values, this.props.paHotspots);
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
      this.props.appdata.settings.gyroscope?.enabled &&
      !this.props.live
    ) {
      gyroscope();
    }
  };

  componentDidUpdate = (prevProps: HotspotsProps) => {
    if (prevProps.appdata !== this.props.appdata) {
      loadHotspots(this.props.appdata, undefined, this.props.paHotspots);
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ) &&
        this.props.appdata.settings.gyroscope?.enabled &&
        !this.props.live
      ) {
        gyroscope();
      }
    }
    if (!this.props.pointAndAsk && prevProps.pointAndAsk) {
      loadHotspots(this.props.appdata, undefined, this.props.paHotspots);
    }
    if (this.props.pointAndAsk && !prevProps.pointAndAsk) {
      stopAutoRotate();
    }
    const selectedSpace = Array.isArray(this.props.selectedSpaceId)
      ? this.props.selectedSpaceId[this.props.timelapseIndex]
      : this.props.selectedSpaceId;
    toggleLeftAndRightHotspots(selectedSpace);
  };

  renderLeftHotspots = (
    scene: MarzipanoScene,
    hotspots: any[],
    nextHotspotTmp: string[] | undefined,
  ) => {
    scene.leftHotspots?.articleHotspots?.map((hotspot, index) => {
      hotspots.push(
        <ArticleHotspot
          key={`article-${scene.id}-${index}`}
          hKey={`article-${scene.id}-${index}`}
          info={hotspot}
          sceneId={scene.id}
          left={true}
        />,
      );
      return true;
    });

    scene.leftHotspots?.infoHotspots?.map((hotspot, index) => {
      let animate = false;
      if (nextHotspotTmp) {
        const hotspotType = nextHotspotTmp[0];
        const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
        animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
      }
      hotspots.push(
        <InfoHotspot
          key={`info-${scene.id}-${index}-left`}
          hKey={`info-${scene.id}-${index}-left`}
          left={true}
          info={hotspot}
          sceneId={scene.id}
          animate={animate}
          animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
          mode={this.props.mode}
          changeMode={this.props.changeMode}
          changeBarLabel={this.props.changeBarLabel}
          unblockArrows={this.props.unblockArrows}
          night={this.props.night}
        />,
      );
      return true;
    });

    scene.leftHotspots?.linkHotspots?.map((hotspot, index) => {
      let animate = false;
      if (nextHotspotTmp) {
        const hotspotType = nextHotspotTmp[0];
        const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
        animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
      }
      hotspots.push(
        <LinkHotspots
          key={`link-${scene.id}-${index}-left`}
          hKey={`link-${scene.id}-${index}-left`}
          left={true}
          link={hotspot}
          sceneId={scene.id}
          click={this.props.linkClick}
          animate={animate}
          animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
          mode={this.props.mode}
          changeMode={this.props.changeMode}
          changeBarLabel={this.props.changeBarLabel}
          unblockArrows={this.props.unblockArrows}
        />,
      );
      return true;
    });

    scene.leftHotspots?.imgHotspots?.map((hotspot, index) => {
      let animate = false;
      if (nextHotspotTmp) {
        const hotspotType = nextHotspotTmp[0];
        const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
        animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
      }
      hotspots.push(
        <ImgHotspot
          key={`img-${scene.id}-${index}-left`}
          hKey={`img-${scene.id}-${index}-left`}
          left={true}
          info={hotspot}
          sceneId={scene.id}
          animate={animate}
          animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
          mode={this.props.mode}
          changeMode={this.props.changeMode}
          changeBarLabel={this.props.changeBarLabel}
          unblockArrows={this.props.unblockArrows}
          night={this.props.night}
        />,
      );
      return true;
    });

    scene.leftHotspots?.labelHotspots?.map((hotspot, index) => {
      let animate = false;
      if (nextHotspotTmp) {
        const hotspotType = nextHotspotTmp[0];
        const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
        animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
      }
      hotspots.push(
        <LabelHotspot
          key={`label-${scene.id}-${index}-left`}
          hKey={`label-${scene.id}-${index}-left`}
          left={true}
          info={hotspot}
          sceneId={scene.id}
          direction="../icon/label-lite.svg"
          animate={animate}
          animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
          mode={this.props.mode}
          changeMode={this.props.changeMode}
          changeBarLabel={this.props.changeBarLabel}
          unblockArrows={this.props.unblockArrows}
        />,
      );
      return true;
    });

    scene.leftHotspots?.mediaHotspots?.map((hotspot, index) => {
      let animate = false;
      if (nextHotspotTmp) {
        const hotspotType = nextHotspotTmp[0];
        const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
        animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
      }
      hotspots.push(
        <MediaHotspot
          key={`media-${scene.id}-${index}-left`}
          hKey={`media-${scene.id}-${index}-left`}
          left={true}
          info={hotspot}
          sceneId={scene.id}
          animate={animate}
          animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
          mode={this.props.mode}
          changeMode={this.props.changeMode}
          changeBarLabel={this.props.changeBarLabel}
          unblockArrows={this.props.unblockArrows}
        />,
      );
      return true;
    });

    scene.leftHotspots?.modalHotspots?.map((hotspot, index) => {
      let animate = false;
      if (nextHotspotTmp) {
        const hotspotType = nextHotspotTmp[0];
        const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
        animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
      }
      hotspots.push(
        <ModalHotspot
          key={`modal-${scene.id}-${index}-left`}
          hKey={`modal-${scene.id}-${index}-left`}
          left={true}
          info={hotspot}
          sceneId={scene.id}
          animate={animate}
          animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
          mode={this.props.mode}
          navBarEnabled={this.props.appdata.settings.navBar?.enabled}
          changeMode={this.props.changeMode}
          changeBarLabel={this.props.changeBarLabel}
          unblockArrows={this.props.unblockArrows}
          night={this.props.night}
        />,
      );
      return true;
    });
  };

  renderRightHotspots = (
    scene: MarzipanoScene,
    hotspots: any[],
    nextHotspotTmp: string[] | undefined,
  ) => {
    scene.rightHotspots?.articleHotspots?.map((hotspot, index) => {
      hotspots.push(
        <ArticleHotspot
          key={`article-${scene.id}-${index}`}
          hKey={`article-${scene.id}-${index}`}
          info={hotspot}
          sceneId={scene.id}
          right={true}
        />,
      );
      return true;
    });

    scene.rightHotspots?.infoHotspots?.map((hotspot, index) => {
      let animate = false;
      if (nextHotspotTmp) {
        const hotspotType = nextHotspotTmp[0];
        const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
        animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
      }
      hotspots.push(
        <InfoHotspot
          key={`info-${scene.id}-${index}-right`}
          hKey={`info-${scene.id}-${index}-right`}
          right={true}
          info={hotspot}
          sceneId={scene.id}
          animate={animate}
          animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
          mode={this.props.mode}
          changeMode={this.props.changeMode}
          changeBarLabel={this.props.changeBarLabel}
          unblockArrows={this.props.unblockArrows}
          night={this.props.night}
        />,
      );
      return true;
    });

    scene.rightHotspots?.linkHotspots?.map((hotspot, index) => {
      let animate = false;
      if (nextHotspotTmp) {
        const hotspotType = nextHotspotTmp[0];
        const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
        animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
      }
      hotspots.push(
        <LinkHotspots
          key={`link-${scene.id}-${index}-right`}
          hKey={`link-${scene.id}-${index}-right`}
          right={true}
          link={hotspot}
          sceneId={scene.id}
          click={this.props.linkClick}
          animate={animate}
          animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
          mode={this.props.mode}
          changeMode={this.props.changeMode}
          changeBarLabel={this.props.changeBarLabel}
          unblockArrows={this.props.unblockArrows}
        />,
      );
      return true;
    });

    scene.rightHotspots?.imgHotspots?.map((hotspot, index) => {
      let animate = false;
      if (nextHotspotTmp) {
        const hotspotType = nextHotspotTmp[0];
        const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
        animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
      }
      hotspots.push(
        <ImgHotspot
          key={`img-${scene.id}-${index}-right`}
          hKey={`img-${scene.id}-${index}-right`}
          right={true}
          info={hotspot}
          sceneId={scene.id}
          animate={animate}
          animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
          mode={this.props.mode}
          changeMode={this.props.changeMode}
          changeBarLabel={this.props.changeBarLabel}
          unblockArrows={this.props.unblockArrows}
          night={this.props.night}
        />,
      );
      return true;
    });

    scene.rightHotspots?.labelHotspots?.map((hotspot, index) => {
      let animate = false;
      if (nextHotspotTmp) {
        const hotspotType = nextHotspotTmp[0];
        const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
        animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
      }
      hotspots.push(
        <LabelHotspot
          key={`label-${scene.id}-${index}-right`}
          hKey={`label-${scene.id}-${index}-right`}
          right={true}
          info={hotspot}
          sceneId={scene.id}
          direction="../icon/label-lite.svg"
          animate={animate}
          animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
          mode={this.props.mode}
          changeMode={this.props.changeMode}
          changeBarLabel={this.props.changeBarLabel}
          unblockArrows={this.props.unblockArrows}
        />,
      );
      return true;
    });

    scene.rightHotspots?.mediaHotspots?.map((hotspot, index) => {
      let animate = false;
      if (nextHotspotTmp) {
        const hotspotType = nextHotspotTmp[0];
        const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
        animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
      }
      hotspots.push(
        <MediaHotspot
          key={`media-${scene.id}-${index}-right`}
          hKey={`media-${scene.id}-${index}-right`}
          right={true}
          info={hotspot}
          sceneId={scene.id}
          animate={animate}
          animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
          mode={this.props.mode}
          changeMode={this.props.changeMode}
          changeBarLabel={this.props.changeBarLabel}
          unblockArrows={this.props.unblockArrows}
        />,
      );
      return true;
    });

    scene.rightHotspots?.modalHotspots?.map((hotspot, index) => {
      let animate = false;
      if (nextHotspotTmp) {
        const hotspotType = nextHotspotTmp[0];
        const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
        animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
      }
      hotspots.push(
        <ModalHotspot
          key={`modal-${scene.id}-${index}-right`}
          hKey={`modal-${scene.id}-${index}-right`}
          right={true}
          info={hotspot}
          sceneId={scene.id}
          animate={animate}
          animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
          mode={this.props.mode}
          navBarEnabled={this.props.appdata.settings.navBar?.enabled}
          changeMode={this.props.changeMode}
          changeBarLabel={this.props.changeBarLabel}
          unblockArrows={this.props.unblockArrows}
          night={this.props.night}
        />,
      );
      return true;
    });
  };

  render() {
    if (this.props.pointAndAsk) return null;
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
    const mobileStyle = mobile ? { zIndex: 399 } : {};

    let hotspots: any[] = [];

    this.props.appdata.scenes.map((scene, sceneIndex) => {
      let code;
      if (window.location.search) {
        const urlParams = new URLSearchParams(window.location.search);
        code = urlParams.get('loc');
      }
      if (code) {
        const values = decodeHash(code);
        if (scene.id === values.space && values.focus) {
          hotspots.push(<PointerSVG key="permalink-key" className="permalink-focus" />);
        }
      }

      if (this.props.appdata.nadir && this.props.appdata.settings.showNadir !== false) {
        if (scene.idLeft && scene.idRight) {
          hotspots.push(
            <Nadir
              key={`nadir-${scene.id}-left`}
              id={`nadirHS ${scene.id} left`}
              click={this.props.appdata.nadir.url}
              backgroundImg={this.props.appdata.nadir.img}
            />,
          );
          hotspots.push(
            <Nadir
              key={`nadir-${scene.id}-right`}
              id={`nadirHS ${scene.id} right`}
              click={this.props.appdata.nadir.url}
              backgroundImg={this.props.appdata.nadir.img}
            />,
          );
        } else {
          hotspots.push(
            <Nadir
              key={`nadir-${scene.id}`}
              id={`nadirHS ${scene.id}`}
              click={this.props.appdata.nadir.url}
              backgroundImg={this.props.appdata.nadir.img}
            />,
          );
        }
      }

      let nextHotspotTmp: string[] | undefined = undefined;

      const selectedSpace = Array.isArray(this.props.selectedSpaceId)
        ? this.props.selectedSpaceId[this.props.timelapseIndex]
        : this.props.selectedSpaceId;

      if (scene.id === selectedSpace) {
        nextHotspotTmp =
          this.props.hotspotSequenceIndex !== undefined && this.props.hotspotSequence
            ? this.props.hotspotSequence[this.props.hotspotSequenceIndex].split('[')
            : undefined;
      }

      this.props.paHotspots.forEach((pa, index) => {
        if (scene.id === pa.scene) {
          hotspots.push(<PAHotspot info={pa} index={index} language={this.props.language} />);
        }
      });

      if (scene.idLeft && scene.idRight) {
        this.renderLeftHotspots(scene, hotspots, nextHotspotTmp);
        this.renderRightHotspots(scene, hotspots, nextHotspotTmp);
      } else {
        scene.articleHotspots?.map((hotspot, index) => {
          hotspots.push(
            <ArticleHotspot
              key={`article-${scene.id}-${index}`}
              hKey={`article-${scene.id}-${index}`}
              info={hotspot}
              sceneId={scene.id}
            />,
          );
          return true;
        });

        scene.infoHotspots?.map((hotspot, index) => {
          let animate = false;
          if (nextHotspotTmp) {
            const hotspotType = nextHotspotTmp[0];
            const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
            animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
          }
          hotspots.push(
            <InfoHotspot
              key={`info-${scene.id}-${index}`}
              hKey={`info-${scene.id}-${index}`}
              info={hotspot}
              sceneId={scene.id}
              animate={animate}
              animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
              mode={this.props.mode}
              changeMode={this.props.changeMode}
              changeBarLabel={this.props.changeBarLabel}
              unblockArrows={this.props.unblockArrows}
              night={this.props.night}
            />,
          );
          return true;
        });

        scene.linkHotspots?.map((hotspot, index) => {
          let animate = false;
          if (nextHotspotTmp) {
            const hotspotType = nextHotspotTmp[0];
            const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
            animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
          }
          hotspots.push(
            <LinkHotspots
              key={`link-${scene.id}-${index}`}
              hKey={`link-${scene.id}-${index}`}
              link={hotspot}
              sceneId={scene.id}
              click={this.props.linkClick}
              animate={animate}
              animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
              mode={this.props.mode}
              changeMode={this.props.changeMode}
              changeBarLabel={this.props.changeBarLabel}
              unblockArrows={this.props.unblockArrows}
            />,
          );
          return true;
        });

        scene.imgHotspots?.map((hotspot, index) => {
          let animate = false;
          if (nextHotspotTmp) {
            const hotspotType = nextHotspotTmp[0];
            const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
            animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
          }
          hotspots.push(
            <ImgHotspot
              key={`img-${scene.id}-${index}`}
              hKey={`img-${scene.id}-${index}`}
              info={hotspot}
              sceneId={scene.id}
              animate={animate}
              animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
              mode={this.props.mode}
              changeMode={this.props.changeMode}
              changeBarLabel={this.props.changeBarLabel}
              unblockArrows={this.props.unblockArrows}
              night={this.props.night}
            />,
          );
          return true;
        });

        scene.labelHotspots?.map((hotspot, index) => {
          let animate = false;
          if (nextHotspotTmp) {
            const hotspotType = nextHotspotTmp[0];
            const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
            animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
          }
          hotspots.push(
            <LabelHotspot
              key={`label-${scene.id}-${index}`}
              hKey={`label-${scene.id}-${index}`}
              info={hotspot}
              sceneId={scene.id}
              direction="../icon/label-lite.svg"
              animate={animate}
              animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
              mode={this.props.mode}
              changeMode={this.props.changeMode}
              changeBarLabel={this.props.changeBarLabel}
              unblockArrows={this.props.unblockArrows}
              index={sceneIndex}
            />,
          );
          return true;
        });

        scene.mediaHotspots?.map((hotspot, index) => {
          let animate = false;
          if (nextHotspotTmp) {
            const hotspotType = nextHotspotTmp[0];
            const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
            animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
          }
          hotspots.push(
            <MediaHotspot
              key={`media-${scene.id}-${index}`}
              hKey={`media-${scene.id}-${index}`}
              info={hotspot}
              sceneId={scene.id}
              animate={animate}
              animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
              mode={this.props.mode}
              changeMode={this.props.changeMode}
              changeBarLabel={this.props.changeBarLabel}
              unblockArrows={this.props.unblockArrows}
            />,
          );
          return true;
        });

        scene.modalHotspots?.map((hotspot, index) => {
          let animate = false;
          if (nextHotspotTmp) {
            const hotspotType = nextHotspotTmp[0];
            const hotspotIndex = Number(nextHotspotTmp[1].replace(/[^-,0-9,.]/g, ''));
            animate = hotspot === (scene as { [key: string]: any })[hotspotType][hotspotIndex];
          }
          hotspots.push(
            <ModalHotspot
              key={`modal-${scene.id}-${index}`}
              hKey={`modal-${scene.id}-${index}`}
              info={hotspot}
              sceneId={scene.id}
              animate={animate}
              animationDuration={this.props.appdata.settings.navBar?.sequenceDuration}
              mode={this.props.mode}
              navBarEnabled={this.props.appdata.settings.navBar?.enabled}
              changeMode={this.props.changeMode}
              changeBarLabel={this.props.changeBarLabel}
              unblockArrows={this.props.unblockArrows}
              night={this.props.night}
            />,
          );
          return true;
        });
      }

      return true;
    });

    return (
      <div className="hotspot-container" style={mobileStyle}>
        {hotspots}
      </div>
    );
  }
}
