import React, { useState, useEffect, useRef, FunctionComponent, CSSProperties } from 'react';
//import Draggable from 'react-draggable';
import { GlobalVars } from '../libs/utils';
import { addEventEmissionOnDrag, removeEventEmissionOnDrag } from '../libs/marzipano';
import RemoteBar from './remoteBar';
import ControlBar from './controlBar';
import { ReactComponent as InfoSVG } from '../icon/info.svg';
import { ReactComponent as SyncSVG } from '../icon/sync-screen.svg';

export interface Props {
  participant: any;
  isLocal?: boolean;
  live: boolean;
}

const Participant: FunctionComponent<Props> = ({ participant, isLocal, live }) => {
  const [videoTracks, setVideoTracks] = useState<any>([]);
  const [audioTracks, setAudioTracks] = useState<any>([]);
  const [stopedVideo, setStopedVideo] = useState<boolean>(
    GlobalVars.startOff || participant.videoTracks.size <= 0,
  );
  const [muted, setMuted] = useState<boolean>(
    GlobalVars.startMuted || participant.audioTracks.size <= 0,
  );
  const [controlling, setControlling] = useState<string>('');
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const mounted = useRef<boolean>(false);

  const trackpubsToTracks = (trackMap: any) =>
    Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter((track) => track !== null);

  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  useEffect(() => {
    mounted.current = true;
    const changeCallback = () => {
      if (
        GlobalVars.socket &&
        GlobalVars.admin &&
        GlobalVars.controller &&
        controlling !== participant.identity &&
        isLocal
      ) {
        GlobalVars.socket.emit('changeController', participant.identity);
        window.removeEventListener('changeController', changeCallback);
      }
    };
    window.addEventListener('changeController', changeCallback);
    return () => {
      mounted.current = false;
      window.removeEventListener('changeController', changeCallback);
    };
  });

  useEffect(() => {
    mounted.current && setVideoTracks(trackpubsToTracks(participant.videoTracks));
    mounted.current && setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track: any) => {
      if (track.kind === 'video') {
        mounted.current && setVideoTracks((videoTracks: any) => [...videoTracks, track]);
        setStopedVideo(!track.isEnabled);
        track.on('enabled', () => {
          // do something with the UI here
          mounted.current && setStopedVideo(false);
        });
        track.on('disabled', (e: any) => {
          // do something with the UI here
          mounted.current && setStopedVideo(true);
        });
      } else if (track.kind === 'audio') {
        mounted.current && setAudioTracks((audioTracks: any) => [...audioTracks, track]);
        setMuted(!track.isEnabled);
        track.on('enabled', () => {
          // do something with the UI here
          mounted.current && setMuted(false);
        });
        track.on('disabled', (e: any) => {
          // do something with the UI here
          mounted.current && setMuted(true);
        });
      }
    };

    const trackUnsubscribed = (track: any) => {
      if (track.kind === 'video') {
        mounted.current &&
          setVideoTracks((videoTracks: any) => videoTracks.filter((v: any) => v !== track));
      } else if (track.kind === 'audio') {
        mounted.current &&
          setAudioTracks((audioTracks: any) => audioTracks.filter((a: any) => a !== track));
      }
    };

    participant.tracks.forEach((publication: any) => {
      if (publication.isSubscribed) {
        trackSubscribed(publication.track);
      }
    });

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      mounted.current && setVideoTracks([]);
      mounted.current && setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    if (GlobalVars.socket) {
      if (isLocal && GlobalVars.controller) {
        mounted.current && setControlling(participant.identity);
        GlobalVars.socket.emit('changeController', participant.identity);
      }
      GlobalVars.socket.on('changeController', (identity: string) => {
        mounted.current && setControlling(identity);
        if (identity === participant.identity && isLocal) {
          GlobalVars.controller = true;
          addEventEmissionOnDrag();
        }
        if (identity !== participant.identity && isLocal) {
          GlobalVars.controller = false;
          removeEventEmissionOnDrag();
        }
        const changeControllerEvent = new Event('changeController');
        window.dispatchEvent(changeControllerEvent);
      });
      if (!GlobalVars.controller) {
        GlobalVars.socket.emit('requestController');
      }
    }
  }, [isLocal, participant]);

  const stopVideo = () => {
    if (isLocal && participant.videoTracks.size > 0) {
      participant.videoTracks.forEach((track: any) => {
        if (stopedVideo) {
          track.track.enable();
        } else {
          track.track.disable();
        }
      });
      mounted.current && setStopedVideo(!stopedVideo);
    }
  };

  const mute = () => {
    if (isLocal && participant.audioTracks.size > 0) {
      participant.audioTracks.forEach((track: any) => {
        if (muted) {
          track.track.enable();
        } else {
          track.track.disable();
        }
      });
      mounted.current && setMuted(!muted);
    }
  };

  let tmp: string[] = participant.identity.split('-');
  tmp.pop();
  const name = tmp.join('-');
  /*let initials = '';
  let nameTmp = name.split(' ');
  for (let i = 0; i < nameTmp.length; i++) {
    initials += nameTmp[i].charAt(0);
  }*/

  const changeController = () => {
    if (GlobalVars.socket && GlobalVars.admin && controlling !== participant.identity) {
      GlobalVars.socket.emit('changeController', participant.identity);
    }
  };

  let participantStyle: CSSProperties | undefined;
  if (!isLocal) {
    if (controlling === participant.identity) {
      participantStyle = { width: '142px', border: '3px solid #92de47', boxSizing: 'border-box' };
    } else {
      participantStyle = { width: '142px' };
    }
  }
  /*if (!isLocal && !GlobalVars.admin) {
    participantStyle = { width: '100%', height: '170px' };
  }*/
  let closeMenuOnClick = () => {
    setShowMenu(false);
    document.getElementById('pano')?.removeEventListener('click', closeMenuOnClick);
  };

  return (
    participant && (
      <div className="participant" style={participantStyle}>
        <div
          className="video-chat-container"
          onClick={() => {
            if (mobile && !isLocal) {
              setShowMenu(true);
              document.getElementById('pano')?.addEventListener('click', closeMenuOnClick);
            }
          }}
        >
          {stopedVideo && <div className="video-overlay">{name}</div>}
          <video ref={videoRef} autoPlay={true} />
        </div>

        <audio ref={audioRef} autoPlay={true} muted={isLocal} />

        {isLocal ? (
          <ControlBar
            mute={muted ? muted : false}
            camera={stopedVideo}
            muteCallback={mute}
            cameraCallback={stopVideo}
            controlling={controlling}
            identity={participant.identity}
            isLocal={isLocal}
            live={live}
          />
        ) : (
          !mobile && (
            <RemoteBar
              mute={muted ? muted : false}
              camera={stopedVideo}
              name={name}
              changeController={changeController}
              live={live}
              removeControll={controlling === participant.identity}
            />
          )
        )}
        {mobile && showMenu && !isLocal && (
          <div
            className="remote-bg"
            onClick={() => {
              setShowMenu(false);
            }}
          >
            <div className={showMenu ? 'remote-tools active' : 'remote-tools'}>
              <div className="remote-tool">
                <InfoSVG />
                {name}
              </div>
              {GlobalVars.admin && controlling !== participant.identity && live && (
                <div
                  className="remote-tool"
                  onClick={() => {
                    changeController();
                    setShowMenu(false);
                  }}
                >
                  <SyncSVG />
                  Dar controlo
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default Participant;
