import React from 'react';
import Marzipano from './MarzipanoViewer';
import './MiniLandingPage.css';

export default class MiniLandingPage extends React.Component {
  render = () => {
    const landingAppdata = {
      scenes: [
        {
          id: 'img 1',
          name: 'Entrance',
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 1500,
          initialViewParameters: {
            yaw: -0.01,
            pitch: 0.21,
            fov: 1.5707963267948966,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
      ],
      name: 'Valsteam',
      settings: {
        mouseViewMode: 'drag',
        autorotateEnabled: false,
        autorotate: {
          yawSpeed: 0.5,
          targetPitch: 0,
          targetFov: 3,
          idleTime: 500,
        },
        fullscreenButton: true,
        viewControlButtons: false,
      },
    };
    return (
      <>
        <Marzipano
          key={'marzKey-mini'}
          appdata={landingAppdata}
          active={true}
          selectedScene={'img 1'}
          timelapseIndex={0}
          type={'360'}
          loaded={true}
          load={() => {}}
          mode={'normal'}
          changeMode={() => {}}
          activate={() => {}}
          live={false}
          show={true}
        />
        <div className="start-container">
          <button
            className="start en"
            onClick={() => {
              window.open(window.location.protocol + '//' + window.location.host + '/?language=en');
            }}
          >
            English
          </button>
          <button
            className="start de"
            onClick={() => {
              window.open(window.location.protocol + '//' + window.location.host + '/?language=de');
            }}
          >
            Deutsch
          </button>
        </div>
      </>
    );
  };
}
