import React from 'react';
import { LinkHotspotProps, HotspotState } from '../types/hotspot';
import './LinkHotspot.css';
import { getNameById, changeCamera } from '../libs/marzipano';
//import linkHotspot from '../icon/next-space.svg';
import { ReactComponent as NextSpaceSVG } from '../icon/type=next-space-up.svg';
import { ReactComponent as NextRouteSVG } from '../icon/type=next-route.svg';
import { ReactComponent as ArrowSVG } from '../svg/up-arrow-v2.svg';
import { MappingData } from '../types/mapping';
import { degToRad } from '../libs/utils';
import { createRef } from 'react';

export default class LinkHotspot extends React.Component<LinkHotspotProps> {
  state: HotspotState = {
    active: false,
  };

  mounted: boolean = false;
  domElement = createRef<HTMLDivElement>();

  componentDidMount = () => {
    this.mounted = true;
    window.addEventListener('closeAllHotspots', () => {
      this.mounted && this.setState({ active: false });
    });
    /*const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );*/
    if (this.domElement) {
      this.domElement.current?.addEventListener('hotspotRegionOn', () => {
        this.mounted && !this.state.hovered && this.setState({ hovered: true });
      });
      this.domElement.current?.addEventListener('hotspotRegionOff', () => {
        this.mounted && this.state.hovered && this.setState({ hovered: false });
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  componentDidUpdate = (prevProps: LinkHotspotProps) => {
    if (prevProps.animate !== this.props.animate) {
      if (this.props.animate) {
        const duration = this.props.animationDuration ? this.props.animationDuration : 1000;
        changeCamera(this.props.link.yaw, this.props.link.pitch, undefined, duration, () => {
          if (this.props.mode === 'normal') {
            this.props.changeMode('paused');
          }
          const label = this.props.link.name ? this.props.link.name : this.props.link.target;
          this.props.changeBarLabel(label);
          this.mounted && this.setState({ active: true });
          this.props.unblockArrows();
        });
      } else {
        this.mounted && this.setState({ active: false });
      }
    }
    if (prevProps.mode !== this.props.mode && this.props.mode === 'normal') {
      this.mounted && this.setState({ active: false });
    }
  };

  handleClick = () => {
    this.props.changeMode('normal');
    this.props.click(
      this.props.link.target,
      this.props.link.targetMapping,
      this.props.link.targetSector,
      this.props.link.lookTo,
    );
  };

  render() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    const activeClass = this.state.active ? 'active' : '';
    let name = this.props.link.name ? this.props.link.name : getNameById(this.props.link.target);

    let compareClass = '';
    if (this.props.left) {
      compareClass = 'left ';
    }
    if (this.props.right) {
      compareClass = 'right ';
    }

    const dropShadowX = this.props.link.rotation
      ? 2 * Math.round(Math.sin(degToRad(this.props.link.rotation.toString())))
      : 0;

    const dropShadowY = this.props.link.rotation
      ? 2 * Math.round(Math.cos(degToRad(this.props.link.rotation.toString())))
      : 0;

    const linkStyle = this.props.link.rotation
      ? {
          transform: `translate(-50%, -50%) rotate(${this.props.link.rotation})`,
          filter: `drop-shadow(${dropShadowX}px ${dropShadowY}px 1px rgba(0, 0, 0, 0.7))`,
        }
      : {
          transform: `translate(-50%, -50%)`,
          filter: 'drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7))',
        };

    const iconStyle = mobile ? { width: '32px', height: '32px' } : {};

    let hotspotIcon = (
      <NextSpaceSVG
        style={{ ...linkStyle, ...iconStyle }}
        onClick={() => {
          const stopDescriptionEvent = new CustomEvent('stopDescription', {
            detail: {},
          });
          window.dispatchEvent(stopDescriptionEvent);
          this.handleClick();
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          return false;
        }}
      />
    );

    if (this.props.link.icon) {
      switch (this.props.link.icon) {
        case 'exit':
          hotspotIcon = (
            <NextRouteSVG
              style={{ ...linkStyle, ...iconStyle }}
              onClick={() => {
                const stopDescriptionEvent = new CustomEvent('stopDescription', {
                  detail: {},
                });
                window.dispatchEvent(stopDescriptionEvent);
                this.handleClick();
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          );
          break;

        default:
          hotspotIcon = (
            <NextSpaceSVG
              style={{ ...linkStyle, ...iconStyle }}
              onClick={() => {
                const stopDescriptionEvent = new CustomEvent('stopDescription', {
                  detail: {},
                });
                window.dispatchEvent(stopDescriptionEvent);
                this.handleClick();
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          );
      }
    }

    if (this.props.link.perspective) {
      hotspotIcon = (
        <div
          className="arrow-container"
          onClick={() => {
            const stopDescriptionEvent = new CustomEvent('stopDescription', {
              detail: {},
            });
            window.dispatchEvent(stopDescriptionEvent);
            this.handleClick();
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <ArrowSVG className="arrow one" />
          <ArrowSVG className="arrow two" />
          <ArrowSVG className="arrow three" />
        </div>
      );
    }

    if (this.props.link.targetMapping) {
      const newMapping: MappingData = require(`../JSON/${this.props.link.targetMapping}`);
      const sectorIdx = this.props.link.targetSector
        ? this.props.link.targetSector
        : newMapping.start.sectorIndex;
      const newCircuit = newMapping.sectors[sectorIdx].name;
      const warnLabel = 'Vai entrar no Circuito: \n' + newCircuit;
      return (
        <div
          ref={this.domElement}
          key={this.props.hKey}
          className={'hotspot-link ' + compareClass + this.props.sceneId}
        >
          <NextRouteSVG
            style={{ ...linkStyle, ...iconStyle }}
            onClick={() => {
              const stopDescriptionEvent = new CustomEvent('stopDescription', {
                detail: {},
              });
              window.dispatchEvent(stopDescriptionEvent);
              this.handleClick();
            }}
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          {this.props.link.showLabel !== false && (
            <span className={this.state.hovered ? 'tooltip hovered' : 'tooltip ' + activeClass}>
              <h6>{name}</h6>
              <p>{warnLabel}</p>
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div
          ref={this.domElement}
          key={this.props.hKey}
          className={'hotspot-link ' + compareClass + this.props.sceneId}
        >
          {hotspotIcon}
          {!this.props.link.perspective && this.props.link.showLabel !== false && (
            <span
              style={{ borderRadius: '5px' }}
              className={this.state.hovered ? 'tooltip hovered' : 'tooltip ' + activeClass}
            >
              {name}
            </span>
          )}
        </div>
      );
    }
  }
}
