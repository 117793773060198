import React from 'react';
import { MediaHotspotProps, HotspotPerspectiveState } from '../types/hotspot';
import './MediaHotspot.css';
import {
  changePerspective,
  stopAutoRotate,
  startAutoRotate,
  changeCamera,
  isFlat,
} from '../libs/marzipano';

export default class MediaHotspot extends React.Component<MediaHotspotProps> {
  state: HotspotPerspectiveState = {
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    width: 0,
    height: 0,
  };

  vidRef = React.createRef<HTMLVideoElement>();

  keysPressed: any = [];

  rotationDegreeIncrement = 0.2;
  dimensionIncrement = 1;

  componentDidMount = () => {
    let x = 0;
    let y = 0;
    let z = 0;
    let width = 0;
    let height = 0;
    if (this.props.info.perspective.extraTransforms) {
      let tmp = this.props.info.perspective.extraTransforms.trim().split(' ');
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].trim().startsWith('rotateX(')) {
          x = Number(tmp[i].replace(/[^-,0-9,.]/g, ''));
        }
        if (tmp[i].trim().startsWith('rotateY(')) {
          y = Number(tmp[i].replace(/[^-,0-9,.]/g, ''));
        }
        if (tmp[i].trim().startsWith('rotateZ(')) {
          z = Number(tmp[i].replace(/[^-,0-9,.]/g, ''));
        }
      }
    }
    if (this.props.info.width) {
      width = Number(this.props.info.width.trim().replace(/[^-,0-9,.]/g, ''));
    }
    if (this.props.info.height) {
      height = Number(this.props.info.height.trim().replace(/[^-,0-9,.]/g, ''));
    }
    this.setState({ rotateX: x, rotateY: y, rotateZ: z, width: width, height: height });
  };

  componentDidUpdate = (prevProps: MediaHotspotProps, prevState: HotspotPerspectiveState) => {
    if (prevState !== this.state && this.props.info.perspective.editMode) {
      const extraTransform = `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`;
      changePerspective(extraTransform, this.props.sceneId, this.props.hKey);
    }
    if (prevProps.animate !== this.props.animate) {
      if (this.props.animate) {
        const duration = this.props.animationDuration ? this.props.animationDuration : 1000;
        changeCamera(this.props.info.yaw, this.props.info.pitch, undefined, duration, () => {
          if (this.props.mode === 'normal') {
            this.props.changeMode('paused');
          }
          const lbl = this.props.info.title ? this.props.info.title : '';
          this.props.changeBarLabel(lbl);
          this.props.unblockArrows();
        });
      }
    }
  };

  mouseHoverHandler = () => {
    stopAutoRotate();
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('keyup', this.keyUp);
  };

  playPause = () => {
    if (this.props.info.options?.playPause) {
      if (this.props.mode === 'autoplay') {
        this.props.changeMode('paused');
      }
      const stopDescriptionEvent = new CustomEvent('stopDescription', {
        detail: {},
      });
      window.dispatchEvent(stopDescriptionEvent);
      if (this.vidRef.current instanceof HTMLVideoElement) {
        if (this.vidRef.current.paused) {
          this.vidRef.current.play();
        } else {
          this.vidRef.current.pause();
        }
      }
    }
  };

  keyDown = (event: any) => {
    this.keysPressed[event.key] = true;
    if (this.keysPressed['w'] && event.key === 'ArrowRight') {
      const width: number = this.state.width ? this.state.width + this.dimensionIncrement : 0;
      this.setState({ width: width });
      console.log(`"width": "${this.state.width}px", "height": "${this.state.height}px",`);
    }
    if (this.keysPressed['w'] && event.key === 'ArrowLeft') {
      const width: number = this.state.width ? this.state.width - this.dimensionIncrement : 0;
      this.setState({ width: width });
      console.log(`"width": "${this.state.width}px", "height": "${this.state.height}px",`);
    }
    if (this.keysPressed['h'] && event.key === 'ArrowRight') {
      const height = this.state.height ? this.state.height + this.dimensionIncrement : 0;
      this.setState({ height: height });
      console.log(`"width": "${this.state.width}px", "height": "${this.state.height}px",`);
    }
    if (this.keysPressed['h'] && event.key === 'ArrowLeft') {
      const height = this.state.height ? this.state.height - this.dimensionIncrement : 0;
      this.setState({ height: height });
      console.log(`"width": "${this.state.width}px", "height": "${this.state.height}px",`);
    }
    if (this.keysPressed['x'] && event.key === 'ArrowRight') {
      const rotateX = this.state.rotateX + this.rotationDegreeIncrement;
      this.setState({ rotateX: rotateX });
      console.log(
        `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`,
      );
    }
    if (this.keysPressed['x'] && event.key === 'ArrowLeft') {
      const rotateX = this.state.rotateX - this.rotationDegreeIncrement;
      this.setState({ rotateX: rotateX });
      console.log(
        `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`,
      );
    }
    if (this.keysPressed['y'] && event.key === 'ArrowRight') {
      const rotateY = this.state.rotateY + this.rotationDegreeIncrement;
      this.setState({ rotateY: rotateY });
      console.log(
        `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`,
      );
    }
    if (this.keysPressed['y'] && event.key === 'ArrowLeft') {
      const rotateY = this.state.rotateY - this.rotationDegreeIncrement;
      this.setState({ rotateY: rotateY });
      console.log(
        `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`,
      );
    }
    if (this.keysPressed['z'] && event.key === 'ArrowRight') {
      const rotateZ = this.state.rotateZ + this.rotationDegreeIncrement;
      this.setState({ rotateZ: rotateZ });
      console.log(
        `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`,
      );
    }
    if (this.keysPressed['z'] && event.key === 'ArrowLeft') {
      const rotateZ = this.state.rotateZ - this.rotationDegreeIncrement;
      this.setState({ rotateZ: rotateZ });
      console.log(
        `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`,
      );
    }
  };

  keyUp = (event: any) => {
    delete this.keysPressed[event.key];
  };

  mouseOutHandler = () => {
    startAutoRotate();
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('keyup', this.keyUp);
  };

  render() {
    let content;
    //TODO width and height should be dynamic but optional
    const width = this.props.info.width ? `${this.state.width}px` : 'auto';
    const height = this.props.info.height ? `${this.state.height}px` : 'auto';
    const transformStyle = isFlat() ? { transform: 'translate(-50%, -50%' } : {};
    if (this.props.info.type === 'video') {
      const cursorStyle = this.props.info.options?.playPause ? { cursor: 'pointer' } : {};
      content = (
        <video
          style={{ ...cursorStyle, ...transformStyle }}
          ref={this.vidRef}
          width={width}
          height={height}
          loop
          autoPlay
          muted={this.props.info.options?.mute}
          onClick={() => {
            this.playPause();
          }}
          onMouseEnter={() => this.mouseHoverHandler()}
          onMouseOut={() => this.mouseOutHandler()}
        >
          <source src={this.props.info.mediaUrl}></source>
        </video>
      );
    }
    if (this.props.info.type === 'image') {
      content = (
        <img
          src={this.props.info.mediaUrl}
          width={width}
          height={height}
          alt=""
          onMouseEnter={() => this.mouseHoverHandler()}
          onMouseOut={() => this.mouseOutHandler()}
        ></img>
      );
    }
    const zidx = this.props.info.perspective.zIndex ? this.props.info.perspective.zIndex : -1;

    let compareClass = '';
    if (this.props.left) {
      compareClass = 'left ';
    }
    if (this.props.right) {
      compareClass = 'right ';
    }

    return this.props.info.perspective.editMode ? (
      <div
        style={{ zIndex: zidx }}
        key={this.props.hKey}
        id={this.props.hKey}
        className={'hotspot-media ' + compareClass + this.props.sceneId}
      >
        {content}
      </div>
    ) : (
      <div
        style={{ zIndex: zidx }}
        key={this.props.hKey}
        className={'hotspot-media ' + compareClass + this.props.sceneId}
      >
        {content}
      </div>
    );
  }
}
