import React, { RefObject } from 'react';
import './modalVideo.template.css';
import { ModalHotspotProps } from '../../types/hotspot';

interface ModalVideoProps extends ModalHotspotProps {
  active: boolean;
}

export default class Modal1 extends React.Component<ModalVideoProps> {
  iframeRef: RefObject<HTMLIFrameElement> = React.createRef();

  componentDidUpdate = () => {
    if (this.iframeRef.current) {
      this.iframeRef.current.onpause = () => {
        console.log('paused');
      };
    }
  };

  render() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    const videoFrame = this.props.active ? (
      <iframe
        ref={this.iframeRef}
        title={this.props.info.description}
        src={`${this.props.info.videoSrc}?controls=1&autoplay=1&autorepeat=1&mute=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    ) : null;

    if (mobile) {
      return (
        <div className="mobile-modal-video">
          {videoFrame}
          <p>{this.props.info.title}</p>
        </div>
      );
    } else {
      return (
        <div className={'modal-video'}>
          {videoFrame}
          <p>{this.props.info.title}</p>
        </div>
      );
    }
  }
}
